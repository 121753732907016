import './App.css';
import ReactPlayer from 'react-player'
import {FaFacebookSquare, FaTelegramPlane, FaDiscord} from "react-icons/fa";
import {AiFillInstagram} from "react-icons/ai";
import {BsYoutube, BsTwitter} from "react-icons/bs";

function App() {
    return (
        <div className="App">
            <section id="section-hero" aria-label="section" className="pt20 pb20 vh-100 section-hero">
                <div id="particles-js"/>
                <div className={'background-wrapper'}>
                    <div className="background"/>
                </div>
                <div className="v-center">
                    <div className={'container'}>
                        <p className={'coming-soon'}>Coming Soon ...</p>
                        <ReactPlayer className={"video-player"} url='https://www.youtube.com/watch?v=uGc2-kIucM4'/>
                        <div className={"socials"}>
                            <FaFacebookSquare className={'social-icons'} onClick={() => {
                                window.location.href = 'https://facebook.com/cybermeta'
                            }}/>
                            <BsTwitter className={'social-icons'} onClick={() => {
                                window.location.href = 'https://twitter.com/cybermetacoin'
                            }}/>
                            <AiFillInstagram className={'social-icons'} onClick={() => {
                                window.location.href = 'https://instagram.com/cybermetacoin'
                            }}/>
                            <FaTelegramPlane className={'social-icons'} onClick={() => {
                                window.location.href = 'https://t.me/CyberMetacoin'
                            }}/>
                            <FaDiscord className={'social-icons'} onClick={() => {
                                window.location.href = 'https://discord.gg/quyKYTtSvY'
                            }}/>
                            <BsYoutube className={'social-icons'} onClick={() => {
                                window.location.href = 'https://youtube.com/channel/UC38Z-19mPnqiZ3nOxxhaYOQ'
                            }}/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default App;
